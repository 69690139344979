<template>
  <ActionModalLayout
    :title="message('title') + ' ' + (item.name ? item.name : '')"
  >
    <div>{{ message('description') }}</div>

    <template #actions>
      <v-btn
        depressed
        color="primary"
        :loading="isLoading"
        @click="handleDelete"
      >
        Подтвердить
      </v-btn>
    </template>
  </ActionModalLayout>
</template>

<script>
import messagable from '@/utils/mixins/messagable';
import { xhrErrorMessage } from '@/utils/helpers';
import ActionModalLayout from '../layouts/ActionModalLayout.vue';

const MESSAGES = {
  title: 'Удалить',
  description: 'Вы точно хотите удалить запись?',
  actionButton: 'Подтвердить',
  successfulAction: 'Запись успешно удалена',
  errorAction: 'Произошла ошибка удаления записи',
};

export default {
  components: {
    ActionModalLayout,
  },
  mixins: [messagable(MESSAGES)],
  props: {
    item: {
      type: Object,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async handleDelete() {
      this.isLoading = true;
      try {
        await this.onDelete(this.item.id || this.item.key);
        this.$notify({
          group: 'note',
          type: 'info',
          title: this.message('successfulAction'),
        });
        this.$emit('close');
      } catch (err) {
        this.$notify({
          group: 'note',
          type: 'error',
          title:
            // the line below is a crutch for medic groups deletion
            err?.response?.data?.inner?.[0]?.errors?.organizations ||
            this.message('errorAction'),
          text: xhrErrorMessage(err),
        });
        this.$emit('close');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
